import React from 'react'
import PropTypes from 'prop-types'

import AlchemyCms from '../images/tools/alchemy-cms.svg'
import AWS from '../images/tools/aws.svg'
import Balsamiq from '../images/tools/balsamiq.svg'
import Bootstrap from '../images/tools/bootstrap.svg'
import Braintree from '../images/tools/braintree.svg'
import CFWheels from '../images/tools/cfwheels.svg'
import Cloud66 from '../images/tools/cloud-66.svg'
import Docker from '../images/tools/docker.svg'
import Fireworks from '../images/tools/fireworks.svg'
import FoundationSites from '../images/tools/foundation-sites.svg'
import Gatsby from '../images/tools/gatsby.svg'
import Heroku from '../images/tools/heroku.svg'
import Illustrator from '../images/tools/illustrator.svg'
import JQuery from '../images/tools/jquery.svg'
import Mailchimp from '../images/tools/mailchimp.svg'
import MySQL from '../images/tools/mysql.svg'
import Netlify from '../images/tools/netlify.svg'
import Photoshop from '../images/tools/photoshop.svg'
import PostgreSQL from '../images/tools/postgresql.svg'
import Rails from '../images/tools/rails.svg'
import ReactLogo from '../images/tools/react-logo.svg'
import Sass from '../images/tools/sass.svg'
import Sketch from '../images/tools/sketch.svg'
import Stimulus from '../images/tools/stimulus.svg'
import Stripe from '../images/tools/stripe.svg'
import SQLServer from '../images/tools/sql-server.svg'

const Sticker = ({ id }) => (
  <figure className="sticker">
    {(() => {
      switch (id) {
        case 'alchemy-cms':
          return (
            <>
              <AlchemyCms />
              <figcaption className="sticker-caption">
                Alchemy CMS
              </figcaption>
            </>
          )
        case 'aws':
          return (
            <>
              <AWS />
              <figcaption className="sticker-caption">
                Amazon Web Services
              </figcaption>
            </>
          )
        case 'balsamiq':
          return (
            <>
              <Balsamiq />
              <figcaption className="sticker-caption">
                Balsamiq
              </figcaption>
            </>
          )
        case 'bootstrap':
          return (
            <>
              <Bootstrap />
              <figcaption className="sticker-caption">
                Bootstrap
              </figcaption>
            </>
          )
        case 'braintree':
          return (
            <>
              <Braintree />
              <figcaption className="sticker-caption">
                Braintree
              </figcaption>
            </>
          )
        case 'cfwheels':
          return (
            <>
              <CFWheels />
              <figcaption className="sticker-caption">
                CFWheels
              </figcaption>
            </>
          )
        case 'cloud-66':
          return (
            <>
              <Cloud66 />
              <figcaption className="sticker-caption">
                Cloud 66
              </figcaption>
            </>
          )
        case 'docker':
          return (
            <>
              <Docker />
              <figcaption className="sticker-caption">
                Docker
              </figcaption>
            </>
          )
        case 'fireworks':
          return (
            <>
              <Fireworks />
              <figcaption className="sticker-caption">
                Adobe Fireworks
              </figcaption>
            </>
          )
        case 'foundation-sites':
          return (
            <>
              <FoundationSites />
              <figcaption className="sticker-caption">
                Foundation for Sites
              </figcaption>
            </>
          )
        case 'gatsby':
          return (
            <>
              <Gatsby />
              <figcaption className="sticker-caption">
                Gatsby
              </figcaption>
            </>
          )
        case 'heroku':
          return (
            <>
              <Heroku />
              <figcaption className="sticker-caption">
                Heroku
              </figcaption>
            </>
          )
        case 'illustrator':
          return (
            <>
              <Illustrator />
              <figcaption className="sticker-caption">
                Adobe Illustrator
              </figcaption>
            </>
          )
        case 'jquery':
          return (
            <>
              <JQuery />
              <figcaption className="sticker-caption">
                jQuery
              </figcaption>
            </>
          )
        case 'mailchimp':
          return (
            <>
              <Mailchimp />
              <figcaption className="sticker-caption">
                Mailchimp
              </figcaption>
            </>
          )
        case 'mysql':
          return (
            <>
              <MySQL />
              <figcaption className="sticker-caption">
                MySQL
              </figcaption>
            </>
          )
        case 'netlify':
          return (
            <>
              <Netlify />
              <figcaption className="sticker-caption">
                Netlify
              </figcaption>
            </>
          )
        case 'photoshop':
          return (
            <>
              <Photoshop />
              <figcaption className="sticker-caption">
                Adobe Photoshop
              </figcaption>
            </>
          )
        case 'postgresql':
          return (
            <>
              <PostgreSQL />
              <figcaption className="sticker-caption">
                PostgreSQL
              </figcaption>
            </>
          )
        case 'react':
          return (
            <>
              <ReactLogo />
              <figcaption className="sticker-caption">
                React
              </figcaption>
            </>
          )
        case 'rails':
          return (
            <>
              <Rails />
              <figcaption className="sticker-caption">
                Ruby on Rails
              </figcaption>
            </>
          )
        case 'sass':
          return (
            <>
              <Sass />
              <figcaption className="sticker-caption">
                Sass
              </figcaption>
            </>
          )
        case 'sketch':
          return (
            <>
              <Sketch />
              <figcaption className="sticker-caption">
                Sketch
              </figcaption>
            </>
          )
        case 'stimulus':
          return (
            <>
              <Stimulus />
              <figcaption className="sticker-caption">
                Stimulus
              </figcaption>
            </>
          )
        case 'stripe':
          return (
            <>
              <Stripe />
              <figcaption className="sticker-caption">
                Stripe
              </figcaption>
            </>
          )
        case 'sql-server':
          return (
            <>
              <SQLServer />
              <figcaption className="sticker-caption">
                SQL Server
              </figcaption>
            </>
          )
        default:
          return (
            <figcaption className="sticker-caption">
              {id}
            </figcaption>
          )
      }
    })()}
  </figure>
)

Sticker.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Sticker
