import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PhotoSwipeWrapper from './photoswipe-wrapper';

const ImageGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const items = images.map(image => {
    const sizeParts = image.size.split('x')

    const srcSet = image.file.childImageSharp.fluid.srcSet.split(',')
    let lgImg = srcSet[srcSet.length - 1]
    lgImg = lgImg.split(' ')[0]

    return {
      src: lgImg,
      thumb: image.thumb.childImageSharp.fixed.src,
      w: sizeParts[0],
      h: sizeParts[1],
      title: image.desc,
    }
  })

  function handleOpen(index) {
    setIsOpen(true)
    setIndex(index)
  }

  function handleClose() {
    setIsOpen(false)
  }

  return (
    <nav className="image-gallery text-center">
      {items.map((item, i) => {
        return (
          <button
            key={i}
            onClick={() => handleOpen(i)}
            aria-label="View full size"
            className="image-gallery-btn"
          >
            <img
              src={item.thumb}
              width="100"
              height="100"
              alt=""
              className="image-gallery-thumb"
            />
          </button>
        )
      })}

      <PhotoSwipeWrapper
        isOpen={isOpen}
        index={index}
        items={items}
        onClose={handleClose}
      />
    </nav>
  )
}

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
}

export default ImageGallery
