import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Sticker from '../components/sticker'
import ImageGallery from '../components/image-gallery'
import Breadcrumbs from '../components/breadcrumbs'

const PortfolioPost = ({ data }) => {
  const meta = data.markdownRemark.frontmatter
  const { contributions, tools } = meta

  return (
    <Layout currentSection="portfolio">
      <SEO
        title="Digital Creativity and Strategy"
        description=""
        canonicalUrl=""
        meta={[
          {
            name: 'twitter:creator',
            content: '@chrisdpeters',
          },
        ]}
      />

      <Section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} lg={10} className="mb-4">
              <Breadcrumbs>
                <Breadcrumbs.Link to="/portfolio/">
                  Portfolio
                </Breadcrumbs.Link>
              </Breadcrumbs>

              <h1>
                {meta.title}
              </h1>

              <p className="text-muted">
                Built in {meta.year}

                {(() => {
                  if (meta.website) {
                    const cta = meta.website.cta || 'Visit website'

                    return (
                      <>
                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        <a
                          href={meta.website.url}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="link-action"
                        >
                          {cta}
                        </a>
                      </>
                    )
                  }
                })()}
              </p>
            </Col>

            <Col xs={12} lg={10}>
              {(() => {
                if (meta.responsivePreview) {
                  return (
                    <Image
                      fluid={meta.responsivePreview.childImageSharp.fluid}
                      alt=""
                      className="mb-4"
                    />
                  )
                }
              })()}
            </Col>

            <Col xs={12} lg={10} className="mb-4">
              {(() => {
                if (meta.imageGallery) {
                  return <ImageGallery images={meta.imageGallery} />
                }
              })()}
            </Col>

            <Col xs={12} lg={10} xl={8}>
              <div dangerouslySetInnerHTML={{
                __html: data.markdownRemark.html
              }} />
            </Col>
          </Row>

          {(() => {
            if (contributions) {
              return (
                <Row className="justify-content-around">
                  <Col xs={12} lg={10} xl={8} className="mt-5">
                    <h2>
                      Project contributions
                    </h2>
                    <ul>
                      {contributions.map(contribution => {
                        return (
                          <li key={contribution}>
                            {contribution}
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                </Row>
              )
            }
          })()}

          {(() => {
            if (tools) {
              return (
                <>
                  <Row className="justify-content-around">
                    <Col xs={12} lg={10} xl={8} className="mt-5">
                      <h2 className="mb-3">
                        Tools used
                      </h2>

                      <Row className="align-items-center">
                        {tools.map(tool => {
                          return (
                            <Col
                              key={tool}
                              xs={6}
                              sm={4}
                              md={3}
                              className="mb-4 text-center"
                            >
                              <Sticker id={tool} />
                            </Col>
                          )
                        })}
                      </Row>
                    </Col>
                  </Row>
                </>
              )
            }
          })()}
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioPost($slug: String!) {
    markdownRemark(
      fields: {
        content_type: { eq: "portfolio" }
        slug: { eq: $slug }
      }
    ) {
      html
      frontmatter {
        title
        year
        website {
          url
          cta
        }
        responsivePreview: responsive_preview {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageGallery: image_gallery {
          thumb {
            childImageSharp {
              fixed(width: 200, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          file {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          size
          desc
        }
        contributions
        tools
      }
    }
  }
`

export default PortfolioPost
